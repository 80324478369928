<template>
  <div class="homepage-container">

    <div class="homepage-homepage">
      <div class="homepage-content">
        <div class="homepage-banner">
          <img
            alt="logo2179"
            src="/external/jixianghua_icon.png"
            class="homepage-logo"
          />
          <span class="homepage-text"><span>过年要说吉祥话</span></span>
          <span class="homepage-text02">
            <span class="homepage-text03">利用AI技术，拯救你空白的大脑。</span>
            <div>
              <a href="https://www.capsuleer.me" ><span style="text-decoration: underline;">by capsuleer</span></a>
            </div>
          </span>
        </div>
        <div class="homepage-frame16">
          <div class="subselection-container">
            <span class="homepage-text13"><span>向谁说</span></span>
          <div  class="button select-stylized-selected" >
            <select v-model="toWhomSelection" @change="onToWhomSelection">
              <option v-for="option in toWhomOptions" :key="option.value" :value="option.value">{{ option.text }}</option>          
            </select>
          </div>
          <textarea v-if="this.toWhomSelection === '7'" class="homepage-textarea" v-model="toWhomAreaText" placeholder="请输入" > </textarea>

          </div>
          <div class="subselection-container">
            <span class="homepage-text13"><span>祝什么</span></span>
          <div  class="button select-stylized-selected" id="withWhatContainer">
            <select v-model="withWhatSelection" @change="onWithWhatSelection">
              <option v-for="option in withWhatOptions" :key="option.value" :value="option.value">{{ option.text }}</option>          
            </select>
          </div>
          <textarea v-if="this.withWhatSelection === '8'" class="homepage-textarea" v-model="withWhatAreaText" placeholder="请输入" > </textarea>

          </div>
          <div class="subselection-container">
            <span class="homepage-text13"><span>怎么祝</span></span>
          <div  class="button select-stylized-selected" id="inStyleContainer">
            <select v-model="inStyleSelection" @change="onInStyleSelection">
              <option v-for="option in inStyleOptions" :key="option.value" :value="option.value">{{ option.text }}</option>          
            </select>
          </div>
          <textarea v-if="this.inStyleSelection === '7'" class="homepage-textarea" v-model="inStyleAreaText" placeholder="请输入" > </textarea>

          </div>
          
          
          
        </div>
 
        <span style="color:#e52a33;" id="error-message" class="hidden"><span>{{ this.errorMessage }}</span></span>

        <div class="homepage-frame4" v-if="!this.requesting">
          <button type="button" @click="StartTrial" class="button button-stylized" v-if="this.withWhatSelection ==0 && this.toWhomSelection ==0">随便来一个</button>
          <!-- <button type="button"  class="button button-stylized" v-if="this.withWhatSelection !=0 || this.toWhomSelection !=0">请全部选满</button> -->
          <button type="button" @click="StartAnalyse" class="button button-stylized" v-if="this.withWhatSelection !=0 && this.toWhomSelection !=0">🎇量身定制</button>
        </div>
        <div class="homepage-frame24" >
          <div class="homepage-response" v-if="tipsHidden">
            <span class="homepage-text18">
              <span>
                <div v-if="this.requesting">正在思考中...</div>
                <div class="response-slot" v-for="(response, index) in reversedResponseArray" :key="index">{{ response }}</div>

              </span>
            </span>
          </div>
          <div class="homepage-frame6" v-if="!tipsHidden">
            <div class="homepage-frame23">
              <img
                src="/external/good.png"
                alt="Vector5977"
                class="homepage-vector"
              />
              <span class="homepage-text16"><span>我能做到...</span></span>
            </div>
            <span class="homepage-text18">
              <span>
                <span>说一句吉祥话</span>
              </span>
            </span>
          </div>
          <div class="homepage-frame8" v-if="!tipsHidden">
            <div class="homepage-frame231">
              <img
                src="/external/error.png"
                alt="Vector5984"
                class="homepage-vector1"
              />
              <span class="homepage-text27"><span>我做不到...</span></span>
            </div>
            <span class="homepage-text29">
              <span>
                <span>祝你老爹学业进步，或祝你两岁的妹妹升官发财</span>
                <br />
                <span>放鞭炮、包饺子、下汤圆、摆神龛</span>
                <br />
                <span>帮你赚钱</span>
                <br />
                <span>人情世故</span>

              </span>
            </span>
          </div>
        </div>
        <!-- <div class="homepage-frame4">
          <button type="button" @click="StartAnalyse" class="button button-stylized">debugMode</button>
        </div> -->
        <!-- 雅思写作帮手已经免费提供了{{estimatedCost}}美元的算力。 -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Homepage',
  metaInfo: {
    title: '吉祥话',
  },
  data(){
    return{
      toWhomOptions: [
        { value: '0', text: '请选择' },
        { value: '1', text: '长辈' },
        { value: '2', text: '父亲' },
        { value: '3', text: '母亲' },
        { value: '4', text: '老师' },
        { value: '5', text: '亲戚' },
        { value: '6', text: '朋友' },
        { value: '7', text: '自定义' }
      ],
      toWhomSelection:0,
      toWhomAreaText:'',
      withWhatOptions: [
        { value: '0', text: '请选择' },
        { value: '1', text: '新年快乐' },
        { value: '2', text: '健康' },
        { value: '3', text: '学业' },
        { value: '4', text: '发财' },
        { value: '5', text: '平安' },
        { value: '6', text: '爱情' },
        { value: '7', text: '祝酒' },
        { value: '8', text: '自定义' }
      ],
      withWhatSelection:0,
      withWhatAreaText:'',
      inStyleOptions: [
        { value: '0', text: '普通' },
        { value: '1', text: '随和' },
        { value: '2', text: '亲密' },
        { value: '3', text: '严肃' },
        { value: '4', text: '尊敬' },
        { value: '5', text: '活泼' },
        { value: '6', text: '年轻' },
        { value: '7', text: '自定义' }
      ],
      inStyleSelection:0,
      inStyleAreaText:'',
      estimatedCost:0,
      errorMessage:'',
      tipsHidden:false,
      requesting:false,
      responseArray:[],
    }
  },
  mounted() {
    //a function that calculate estimated cost based on time
    // this.onInStyleSelection();
    var timeOfPublish = new Date('2024-02-09');
    var timeNow = Date.now()
    // console.log(timeOfPublish);
    //calculate seconds
    var seconds = (timeNow -timeOfPublish) / 1000;
    var cost = 0.0001 * seconds;
    cost = cost.toFixed(2);
    this.estimatedCost = cost;
  },
  computed: {
    toWhomSelectionText: {
      get() {
        return this.toWhomSelection === '7' ? this.toWhomAreaText : this.toWhomOptions.find(option => option.value === this.toWhomSelection).text;
      }
    },
    withWhatText: {
      get() {
        return this.withWhatSelection === '8' ? this.withWhatAreaText : this.withWhatOptions.find(option => option.value === this.withWhatSelection).text;
      }
    },
    inStyleText: {
      get() {
        if(this.inStyleSelection === 0){
          return '普通';
        }
        return this.inStyleSelection === '7' ? this.inStyleAreaText : this.inStyleOptions.find(option => option.value === this.inStyleSelection).text;
      }
    },
    promptText: {
      get() {
        return `我向${this.toWhomSelectionText}说吉祥话，目的是祝${this.withWhatText}，要${this.inStyleText}些。`
      }
    },
    reversedResponseArray() {
      // Reverse the order of responseArray
      if(this.responseArray.length > 0){
        return this.responseArray.slice().reverse();
      }else if(this.requesting){
        return [];
      }else{
        return ["目前还没有吉祥话，快来试试吧！"];
      }
    }
  },
  methods: {
    onToWhomSelection() {
      const selectedOption = this.toWhomOptions.find(option => option.value === this.toWhomSelection);

      // Log both the selected value and text
      // console.log('Selected Value:', this.toWhomSelection);
      // console.log('Selected Text:', this.toWhomSelectionText);
    },
    onWithWhatSelection() {
      const selectedOption = this.withWhatOptions.find(option => option.value === this.withWhatOptions);
      // Log both the selected value and text
      // console.log('Selected Value:', this.withWhatSelection);
      // console.log('Selected Text:', this.withWhatText);
    },
    onInStyleSelection() {
      const selectedOption = this.inStyleOptions.find(option => option.value === this.inStyleOptions);
      // Log both the selected value and text
      // console.log(this.promptText);
      // console.log('Selected Value:', this.inStyleSelection);
      // console.log('Selected Text:', this.inStyleText);
    },
    StartTrial(){
      this.RequestPrompt("随便来一句");
      // this.$router.replace({ path: '/result', query: { isTrial : 1,data: "Today, many ads are all about showcasing new and cool stuff. This essay will look into why this happens and why it's generally a good thing. One reason is that people just love new things. Studies on how people think when they shop show that we are naturally attracted to things that are different and fresh. For example, when phone companies talk about the cool features of their latest models like super cameras or folding screens, they know we're likely to be interested because we're curious creatures. Another reason for promoting products as innovative is that it makes us think they're more valuable. If something is labeled as cutting-edge, it gives the impression that it's better than other similar things. This can be a big deal for people who want the best experience and feel like they're getting their money's worth. They might choose a pricier product just because it's got the latest and greatest features. Even though there are some downsides to this, like encouraging a constant need for new stuff and making things obsolete quicker, the overall effect of this kind of advertising is mostly good. The focus on new stuff pushes companies to come up with cool and useful things, making life better for many people. It also pushes businesses to invest in new ideas, helping the economy grow and creating more jobs. So, even though there are some not-so-great things about it, the emphasis on new and cool things in advertising is mostly a good thing." } });
    },
    ShowErrorMessage(string){
      document.getElementById('error-message').classList.remove('hidden');
      this.errorMessage = string;
      setTimeout(() => {
        document.getElementById('error-message').classList.add('hidden');

      }, 5000);
    },
    StartAnalyse(){
      //if textareaData is less than 200 words, alert
      if(this.withWhatText === '' || this.toWhomSelectionText === ''){

        this.ShowErrorMessage('请输入自定义内容')
        return;
      }
      this.RequestPrompt(this.promptText);
      // this.$router.push({ path: '/result', query: { data: this.promptText } });
      
    },
    async RequestPrompt(prompt){
      this.requesting = true;
      this.tipsHidden = true;
      // this.responseArray.push("正在思考中...");
      await axios.post('https://jixiang.capsuleer.me/api_jixiang', {"answerResponse":prompt})
        .then(response => {
          this.requesting = false;
          
          try{
          // if(response.hasOwnProperty("data")){
            // console.log(response);
            this.responseArray.push(response.data);
          // }
          // Assuming the response contains a property called 'jsonData'
          // console.log(response)
          }catch(e){
            this.ShowErrorMessage("发生错误，可能太多人想说吉祥话了，去吃点水果，稍后再试。" + e)
            this.requesting = false;
            console.log(e);
            
          }
          
        })
        .catch(error => {
          this.ShowErrorMessage("发生错误，可能太多人想说吉祥话了，去吃点水果，稍后再试。" + error)
          this.requesting = false;
          console.log(error);

          return;
          
        });
    
      }
  }
}
</script>

<style scoped>
.hidden{
  display: none !important;
}
.homepage-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.homepage-homepage {
  gap: 10px;
  width: 100%;
  display: flex;
  padding: 107px 93px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  background-color: rgba(253, 253, 253, 1);
}
.homepage-content {
  gap: 57px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.homepage-banner {
  gap: 20px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.homepage-logo {
  width: 86px;
  height: 86px;
}
.homepage-text {
  color: #e52a33;
  height: auto;
  font-size: 24px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.homepage-text02 {
  color: rgba(108, 114, 128, 1);
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: Regular;
  text-align: center;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.homepage-text03 {
  font-weight: 400;
}
.homepage-frame15 {
  gap: 12px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.homepage-text09 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  font-style: Medium;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.homepage-textarea {
  gap: 10px;
  height: 60px;
  width: 120px;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: #595959;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: #ededed;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  font-style: normal;
    font-family: Noto Sans SC;
    padding-top: 10px;
    padding-left: 10px;
}
.homepage-text11 {
  top: 8.117088317871094px;
  left: 19px;
  color: rgba(153, 153, 153, 1);
  width: auto;
  height: auto;
  z-index: 1;
  position: absolute;
  font-size: 20px;
  font-style: Regular;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.homepage-frame16 {
  gap: 12px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.homepage-text13 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  font-style: Medium;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.homepage-textarea1 {
  gap: 10px;
  height: 135px;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: #595959;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: #ededed;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.homepage-text15 {
  top: 8.117088317871094px;
  left: 19px;
  color: rgb(153, 153, 153);
  width: auto;
  height: auto;
  z-index: 1;
  position: absolute;
  font-size: 20px;
  font-style: Regular;
  text-align: left;
  font-family: "Noto Sans SC";
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.homepage-frame4 {
  gap: 10px;
  display: flex;
  flex-direction: row;
  align-self: stretch;
  align-items: flex-end;
  justify-content: center;
}
@media(max-width: 991px) {
  .homepage-frame4 {
    align-items: center;
    flex-direction: row;
    /* justify-content: flex-end; */
  }
}
@media(max-width: 767px) {
  .homepage-homepage {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media(max-width: 479px) {
  .homepage-homepage {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.homepage-frame24 {
  gap: 10px;
  width: 100%;
  height: auto;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  padding-top: 8px;
  border-color: #d6d6d6;
  border-width: 1px;
  justify-content: center;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.homepage-frame6 {
  gap: 11px;
  display: flex;
  padding: 20px 34px;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  border-color: rgba(10, 72, 0, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 12px;
  flex-direction: column;
  background-color: rgba(226, 235, 225, 1);
}
.homepage-response {
  gap: 11px;
  display: flex;
  padding: 20px 34px;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  border-color: #b8b8b8;
  border-style: solid;
  border-width: 1px;
  border-radius: 12px;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
}
.homepage-frame23 {
  gap: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.homepage-vector {
  width: 20px;
  height: 20px;
}
.homepage-text16 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  font-style: Medium;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.homepage-text18 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.homepage-frame8 {
  gap: 11px;
  display: flex;
  padding: 20px 34px;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  border-color: rgba(126, 8, 20, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 12px;
  flex-direction: column;
  background-color: rgba(235, 227, 225, 1);
}
.homepage-frame231 {
  gap: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.homepage-vector1 {
  width: 20px;
  height: 20px;
}
.homepage-text27 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  font-style: Medium;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.homepage-text29 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
@media(max-width: 991px) {
  .homepage-frame4 {
    align-items: center;
    flex-direction: row;
    /* justify-content: flex-end; */
  }
}
@media(max-width: 767px) {
  .homepage-homepage {
    padding-left: 40px;
    padding-right: 40px;
  }
  .homepage-frame24 {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .homepage-homepage {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.subselection-container{
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
}
.response-slot{
  margin: 5px;
  padding: 5px;
  border: 1px solid #9e9e9e;
  border-radius: 5px;
  background-color: #f5f5f5;
  font-size: 16px;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
  color: #000000;
}

</style>
